import React, {useEffect, useState} from 'react'
import {useQuery, useMutation} from 'react-query'
import * as homeQueries from 'queries/home.queries'
import {QUERIES} from 'constants/index'
import moment from 'moment'
import * as Styled from './bookingview.styled'
import {Link, useNavigate} from 'react-router-dom'
import {
    Button,
    Col,
    Divider,
    Image,
    List,
    Result,
    Row,
    Spin,
    Tag,
    Typography,
    Modal,
    message,
    Input,
    Checkbox
} from 'antd'
import Item from './Item'
import axios from 'axios'
import ModalImage from 'react-modal'
import {ImageViewer} from 'react-image-viewer-dv'
import ReactJson from 'react-json-view'
import ReactJsonViewCompare from 'react-json-view-compare';

const {Title} = Typography
const { TextArea } = Input;

function BookingView({params}) {
    const [isOpen, setIsOpen] = useState(false)
    const [payout, setPayout] = useState(false)
    const [orders, setOrders] = useState([])
    const [messagess, setMessagess] = useState([])
    const [flag, setFlag] = useState([])
    const token = localStorage.getItem('token')
    // const [homeServices, setHomeServices] = useState(parseJSON( orders?.home_svc_infos))

    // delete all popup functions
    const [visible, setVisible] = React.useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [OrderCancelModal, setOrderCancelModal] = useState(false)
    const [OrderCancelReason, setOrderCancelReason] = useState('')
    const [viewOrderJson, setViewOrderJson] = useState(false)
    const [orderJson,setOrderJson] = useState([])

    const navigate = useNavigate()

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = () => {
        setConfirmLoading(true)
        axios
            .delete(
                `${process.env.REACT_APP_BASE_URL}/admin/orders/${params.id}`,
                {
                    headers: {
                        Authorization: token,
                    },
                },
            )
            .then(() => {
                setVisible(false)
                setConfirmLoading(false)
                navigate('/orders')
                window.location.reload()
            })
            .catch(error => {
                console.log(error)
                setConfirmLoading(false)
            })
    }

    function cancel(reason) {
        // e.preventDefault()

        axios
            .delete(
                `${process.env.REACT_APP_BASE_URL}/admin/orders/cancel-order/${params.id}`,
                {
                    headers: {
                        Authorization: token,
                    },
                    data: {
                        cancellation_reason: reason,
                    },
                },
            )
            .then(res => {
                if (res.data?.success == true) {
                    message.success(res.data.message)
                    setOrderCancelReason('')
                    setOrderCancelModal(false)
                    setInterval(() => {
                        window.location.reload()
                    }, 500)
                } else {
                    message.error('Please Try Again')
                }
            })
            .catch(err => {
                message.error('Please Try Again',err.message)
            })
    }

    function openCloseOrderCancelModal() {
        if (OrderCancelModal) {
            setOrderCancelModal(false)
        } else {
            setOrderCancelModal(true)
        }
    }

    function ConfirmOrderCancel() {
        const reason = OrderCancelReason
        cancel(reason)
    }

    const handleOrderCancelReason = e => {
        setOrderCancelReason(e.target.value)
    }

    // Helper function to check if the content is an image URL
    const isImage = url => {
        if (!url) return false
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
        const extension = url.split('.').pop().toLowerCase()
        return imageExtensions.includes(extension)
    }

    const handleModalOpen = () => {
        console.log('True')
        setIsModalOpen(true)
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
    }

    function cancellationFee(e) {
        e.preventDefault()
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/admin/order_declines/access_gopher_cancelation_fee/${params.id}`,
                {
                    headers: {
                        Authorization: token,
                    },
                },
            )
            .then(res => {
                window.location.reload()
                console.log(res)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const confirmPayoutMutate = useMutation(homeQueries.confirmPayout)
    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const filterQuery = useQuery(
        [QUERIES.GET_BOOKINGS_BY_FILTERS, {id: params.id}],
        homeQueries.getBookingDetails,
        {
            staleTime: 180000,
            retry: 0,
        },
    )

    const GetOrderJson = async ()=>{
        try {
            axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/admin/orders/json/${params.id}`,
                {
                    headers: {
                        Authorization: token,
                    },
                },
            )
            .then(({data}) => {
                if(data.success){
                    setViewOrderJson(true)
                    setOrderJson(data.data)
                }
            })
            .catch(error => {
               message.error(error.message)
            })

        } catch (error) {
            message.error(error.message)
        }

    }

    useEffect(() => {
        setPayout(true)
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/admin/orders/get_details?id=${params.id}`,
                {
                    headers: {
                        Authorization: token,
                    },
                },
            )
            .then(res => {
                setOrders(res.data.data[0])
                setPayout(false)
            })
            .catch(error => {
                console.log(error)
                setPayout(false)
            })
    }, [])

    const order = filterQuery.data?.data[0]
    // filterQuery.isSuccess &&
    // filterQuery.data.data &&
    // filterQuery.data.data[0]

    if (filterQuery.isSuccess && !orders) {
        return (
            <Styled.Root>
                <Result
                    status="warning"
                    title="Unable to find order details!"
                    extra={
                        <Link to="/">
                            <Button type="primary" key="home">
                                Go to home
                            </Button>
                        </Link>
                    }
                />
            </Styled.Root>
        )
    }

    if (filterQuery.isLoading) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }
    console.log(orders)

    const images = orders?.order_attachments
    const age_restricted_attachment_id = orders?.age_restricted_attachment_id
    const ratings = orders?.ratings
    const inApp = orders?.inapp_gophers
    const offers = orders?.gopher_offers
    const counterOffers = orders?.counter_offers
    const reports = orders?.reports
    const orderCogs = orders?.orders_cogs
    const bids = orders?.bids
    const offer_by_gopher = orders?.offer_by_gopher
    const orderLogs = orders?.order_logs
    const emergency = orders?.emergency
    const homeServices =
        orders?.home_svc_infos != undefined ? orders?.home_svc_infos : {}

    function chat(index) {
        const newMessage = inApp[index]?.messages
        const newFlag = inApp[index]?.flags
        setMessagess(newMessage)
        setFlag(newFlag)
        openModal()
    }

    const payoutFee =
        orders.payout_fee === ' ' ? '8' : `${orders.payout_fee * 100}`

    const costOfGoods = parseFloat(orders.cost_of_goods / 100).toFixed(2)

    const OrderOffer = parseFloat(orders.offer / 100).toFixed(2)

    const gopherFee = parseFloat(orders.application_fee / 100).toFixed(2)
    const Discount = parseFloat(orders.discount / 100).toFixed(2)
    const age_restricted_fee = parseFloat(
        orders.age_restricted_fee / 100 || 0,
    ).toFixed(2)
    const age_restricted_discount = parseFloat(
        orders.age_restricted_discount / 100 || 0,
    ).toFixed(2)

    const instantResult =
        ((parseFloat(costOfGoods) +
            parseFloat(gopherFee) +
            parseFloat(age_restricted_fee) -
            parseFloat(age_restricted_discount) +
            parseFloat(OrderOffer)) *
            payoutFee) /
        100

    // const finalResult =
    //     parseFloat(costOfGoods) +
    //     parseFloat(gopherFee) +
    //     parseFloat(OrderOffer) +
    //     parseFloat(instantResult) -
    //     parseFloat(Discount)

    const finalResult =
        parseFloat(costOfGoods) +
        parseFloat(gopherFee) +
        parseFloat(OrderOffer) +
        parseFloat(age_restricted_fee) +
        parseFloat(instantResult) -
        parseFloat(Discount) -
        parseFloat(age_restricted_discount)

    function confirmPayout(e) {
        e.preventDefault()
        setPayout(true)
        confirmPayoutMutate.mutate(
            {queryKey: {id: params.id}},
            {
                onSuccess: () => {
                    window.location.reload()
                },
            },
        )
        setPayout(false)
    }

    if (payout === true) {
        return (
            <Styled.Root>
                <Spin />
            </Styled.Root>
        )
    }

    return (
        <>
            {orders && (
                <>
                    <div>
                        {/* {orders?.payment_status == 'authorized' && (
                            <Row>
                                <Col>
                                    <List
                                        size="small"
                                        bordered
                                        style={{
                                            background: '#fafafa',
                                            boxShadow: '0 5px 10px #eee',
                                        }}>
                                        <List.Item>
                                            <Button
                                                onClick={e => confirmPayout(e)}
                                                type="primary"
                                                style={{ width: '100%' }}>
                                                Confirm Payout
                                            </Button>
                                        </List.Item>
                                    </List>
                                </Col>
                            </Row>
                        )}

                        <Modal
                            title="Delete User"
                            visible={visible}
                            onOk={handleOk}
                            confirmLoading={confirmLoading}
                            onCancel={handleCancel}>
                            <p>Are You sure You want to delete this Order?</p>
                        </Modal>

                        <Button
                            style={{
                                height: 'max-content',
                                width: '10rem',
                                backgroundColor: 'red',
                                color: '#fff',
                                marginTop: '0.5rem',
                            }}
                            onClick={showModal}>
                            Delete Order
                        </Button>

                        {orders?.aasm_state !== 'cancelled' && (
                            <Button
                                type="primary"
                                style={{
                                    height: 'max-content',
                                    width: '10rem',
                                    marginLeft: '1rem',
                                    color: '#fff',
                                    marginTop: '0.5rem',
                                }}
                                onClick={cancel}>
                                Cancel Order
                            </Button>
                        )} */}
                        <div className="order">
                            <Col sm={4} md={6} xl={10}>
                                <List
                                    size="small"
                                    bordered
                                    style={{
                                        background: 'rgba(0,0,0,0.03)',
                                        marginBottom: 20,
                                    }}
                                    header={
                                        <Title level={5}>Basic Info</Title>
                                    }>
                                    <Item body={orders?.id} title="ID#" />

                                    {/* <Item
                                        body={
                                            <>
                                                <div style={{float: 'right'}}>
                                                    {offer_by_gopher == true ? (
                                                        <Tag color="green">
                                                            Offer By Gopher
                                                        </Tag>
                                                    ) : null}
                                                    {emergency == true ? (
                                                        <Tag color="red">
                                                            Emergency
                                                        </Tag>
                                                    ) : null}
                                                </div>
                                            </>
                                        }
                                    /> */}

                                    <Item
                                        body={<Tag>{orders?.aasm_state}</Tag>}
                                        title="Order Status"
                                    />
                                    <Item
                                        body={
                                            <Tag>{orders?.payment_status}</Tag>
                                        }
                                        title={'Payment status'}
                                    />
                                    <Item body={orders?.title} title="Title" />
                                    <Item
                                        body={orders?.description}
                                        title="Description"
                                    />
                                    <Item
                                        body={
                                            orders?.special_instructions ||
                                            'N/A'
                                        }
                                        title="Special Instruction"
                                    />
                                    <Item
                                        body={
                                            orders.gopher_id ? (
                                                <Link
                                                    style={{textTransform: 'capitalize'}}
                                                    to={`/user/${orders.gopher_id}`}>
                                                    {orders?.gopher_name}
                                                </Link>
                                            ) : (
                                                <Tag></Tag>
                                            )
                                        }
                                        title="Gopher"
                                    />
                                     <Item
                                        body={
                                            <Link
                                                style={{textTransform: 'capitalize'}}
                                                to={`/user/${orders?.requestor_id}`}>
                                                {orders?.requestor_name}
                                            </Link>
                                        }
                                        title="Requester"
                                    />
                                    <Item
                                        body={
                                            orders.age_restricted === true ? (
                                                <Tag color="green">Yes</Tag>
                                            ) : (
                                                <Tag color="red">No</Tag>
                                            )
                                        }
                                        title="Age restricted"
                                    />
                                    <Item
                                        body={orders?.gophermode || 'N/A'}
                                        title={'Gopher Mode '}
                                    />
                                    <Item
                                        body={
                                            <>
                                                <div style={{float: 'left'}}>
                                                    {offer_by_gopher == true ? (
                                                        <Tag color="green">
                                                            Yes
                                                        </Tag>
                                                    ) : (
                                                        <Tag color="red">
                                                            No
                                                        </Tag>
                                                    )}
                                                    {emergency == true ? (
                                                        <Tag color="red">
                                                            Emergency
                                                        </Tag>
                                                    ) : null}
                                                </div>
                                            </>
                                        }
                                        title={'Offer By Gopher'}
                                    />

                                    <Item
                                        body={
                                            orders?.request_schedule_now == true
                                                ? 'Need it now'
                                                : (orders?.request_flexible_type ==
                                                      0 &&
                                                  orders?.request_schedule_later ==
                                                      true
                                                      ? 'Scheduled'
                                                      : orders?.request_schedule_later ==
                                                            true &&
                                                        orders?.request_flexible_type ==
                                                            1
                                                      ? 'Scheduled (Anytime )'
                                                      : orders?.request_schedule_later ==
                                                            true &&
                                                        orders?.request_flexible_type ==
                                                            2 &&
                                                        orders?.request_flexible_type !=
                                                            0
                                                      ? 'Scheduled(Sometime this week)'
                                                      : 'Scheduled(In the next week)') ||
                                                  'N/A'
                                        }
                                        title={'Request Complete By'}
                                    />
                                    {/* </div> */}
                                </List>
                            </Col>
                            <Col sm={16} md={12} xl={8}>
                                <List
                                    size="small"
                                    bordered
                                    style={{
                                        background: 'rgba(0,0,0,0.03)',
                                        marginBottom: 20,
                                    }}>
                                    <Item
                                        body={orders?.coupon_code || 'N/A'}
                                        title={'Coupon Code '}
                                    />

                                    <Item
                                        body={
                                            orders?.coupon_id ? (
                                                <Link
                                                    to={`/promocodes?editMode=true&coupon_id=${orders?.coupon_id}`}>
                                                    {orders?.coupon_id}
                                                </Link>
                                            ) : (
                                                'N/A'
                                            )
                                        }
                                        title={'Coupon ID '}
                                    />
                                    <Item
                                        body={`${payoutFee}%`}
                                        title={'Payout Fee '}
                                    />
                                    <Item
                                        //   <Item body={`$ ${parseFloat(counter.order_offer / 100).toFixed(2)}`} title="Order Offer" />
                                        body={`$ ${
                                            orders?.adjustable_amount / 100
                                                ? parseFloat(
                                                      orders.adjustable_amount /
                                                          100,
                                                  ).toFixed(2)
                                                : 0
                                        }`}
                                        title={'Adjustable Amount'}
                                    />
                                    <Item
                                        body={orders?.preferred_delivery_time}
                                        title={'Preferred delivery time (min.)'}
                                    />

                                    <Item
                                        body={
                                            orders?.delivery_eta !== null
                                                ? moment(
                                                      orders?.delivery_eta,
                                                  ).format('YYYY-DD-MM hh:mm A')
                                                : 'N/A'
                                        }
                                        title="Broadcast Expiration"
                                    />

                                    <Item
                                        body={moment(orders?.created_at).format(
                                            'YYYY-DD-MM hh:mm A',
                                        )}
                                        title="Created at"
                                    />
                                    <Item
                                        body={
                                            orders?.request_schedule_time !==
                                            null
                                                ? moment(
                                                      orders?.request_schedule_time,
                                                  ).format('YYYY-DD-MM hh:mm A')
                                                : 'N/A'
                                        }
                                        title="Scheduled Request time"
                                    />
                                    <Item
                                        body={moment(orders?.updated_at).format(
                                            'YYYY-DD-MM hh:mm A',
                                        )}
                                        title="Updated at"
                                    />
                                    {orders?.address_id && (
                                        <Item
                                            body={
                                                <Link
                                                    to={`/address/${orders?.address_id}`}>
                                                    {orders?.address_text}
                                                </Link>
                                            }
                                            title={'Address'}
                                        />
                                    )}
                                    {orders?.pick_address_id && (
                                        <Item
                                            body={
                                                <Link
                                                    to={`/address/${orders?.pick_address_id}`}>
                                                    {orders?.pick_address_text}
                                                </Link>
                                            }
                                            title={'Pickup Address'}
                                        />
                                    )}
                                    {orders?.drop_address_id && (
                                        <Item
                                            body={
                                                <Link
                                                    to={`/address/${orders?.drop_address_id}`}>
                                                    {orders?.drop_address_text}
                                                </Link>
                                            }
                                            title={'Drop Off Address'}
                                        />
                                    )}
                                    {orders?.title &&
                                        orders?.title.indexOf('Moving') >
                                            -1 && (
                                            <Item
                                                body={`${orders?.total_stair_flight}`}
                                                title={'Total Stairs Flights'}
                                            />
                                        )}
                                    {orders?.title &&
                                        orders?.title.indexOf('Moving') >
                                            -1 && (
                                            <Item
                                                body={`${orders?.no_of_item}`}
                                                title={'No of Items'}
                                            />
                                        )}
                                    {orders?.title &&
                                        orders?.title.indexOf('Moving') >
                                            -1 && (
                                            <Item
                                                body={`${orders?.gopher_needed}`}
                                                title={'Gopher Needed '}
                                            />
                                        )}
                                    {orders?.title &&
                                        orders?.title.indexOf('Delivery') >
                                            -1 && (
                                            <Item
                                                body={`${
                                                    orders?.purchase_anywhere ==
                                                    true
                                                        ? 'Yes'
                                                        : 'No'
                                                }`}
                                                title={
                                                    'Gopher can purchase from anywhere'
                                                }
                                            />
                                        )}
                                </List>
                            </Col>

                            <Col sm={4} md={6} xl={6}>
                                <List
                                    size="small"
                                    bordered
                                    style={{
                                        background: 'rgba(0,0,0,0.03)',
                                        marginBottom: 20,
                                    }}>
                                    {orders?.payment_status == 'authorized' && (
                                        <List.Item>
                                            <Button
                                                onClick={e => confirmPayout(e)}
                                                type="primary"
                                                style={{width: '100%'}}>
                                                Confirm Payout
                                            </Button>
                                        </List.Item>
                                    )}

                                    <Modal
                                        title="Delete User"
                                        visible={visible}
                                        onOk={handleOk}
                                        confirmLoading={confirmLoading}
                                        onCancel={handleCancel}>
                                        <p>
                                            Are You sure You want to delete this
                                            Order?
                                        </p>
                                    </Modal>
                                    <List.Item>
                                        <Button
                                            style={{
                                                height: 'max-content',
                                                width: '100%',
                                                backgroundColor: 'red',
                                                color: '#fff',
                                                marginTop: '0.5rem',
                                            }}
                                            onClick={showModal}>
                                            Delete Order
                                        </Button>
                                    </List.Item>

                                    {orders?.aasm_state !== 'cancelled' && (
                                        <List.Item>
                                            <Button
                                                type="primary"
                                                style={{
                                                    height: 'max-content',
                                                    width: '100%',

                                                    color: '#fff',
                                                    marginTop: '0.5rem',
                                                }}
                                                onClick={
                                                    openCloseOrderCancelModal
                                                }>
                                                Cancel Order
                                            </Button>
                                        </List.Item>
                                    )}

                                    {orders?.revoked === 2 &&
                                        orders?.charge_fee === false && (
                                            <List.Item>
                                                <Button
                                                    style={{
                                                        height: 'max-content',
                                                        width: '100%',
                                                        backgroundColor: 'red',
                                                        color: '#fff',
                                                        marginTop: '0.5rem',
                                                        //fontWeight:'bold'
                                                    }}
                                                    onClick={cancellationFee}>
                                                    Assess Gopher Cancellation
                                                    Fee
                                                </Button>
                                            </List.Item>
                                        )}
                                    {orderJson && (
                                        <List.Item>
                                            <Button
                                                type="primary"
                                                style={{
                                                    height: 'max-content',
                                                    width: '100%',

                                                    color: '#fff',
                                                    marginTop: '0.5rem',
                                                }}
                                                onClick={() => {
                                                    GetOrderJson()
                                                }}>
                                                View Order JSON
                                            </Button>
                                        </List.Item>
                                    )}
                                    {orderLogs?.length !== 0 && (
                                        <div style={{margin: '20px'}}>
                                            <Divider
                                                type="horizontal"
                                                orientation="center">
                                                Order Log(s)
                                            </Divider>
                                            <table
                                                className="border"
                                                border={1}>
                                                <tr>
                                                    <th>Date & Time</th>
                                                    <th>Notes</th>
                                                </tr>
                                                {orderLogs?.map(logs => (
                                                    <tr className="">
                                                        {logs.type ===
                                                        'info' ? (
                                                            <>
                                                                <td
                                                                    className="border w-[45%]"
                                                                    style={{
                                                                        padding:
                                                                            '5px',
                                                                    }}>
                                                                    {moment(
                                                                        logs?.created_at ||
                                                                            moment(),
                                                                    ).format(
                                                                        'YYYY-DD-MM hh:mm A',
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className="border"
                                                                    style={{
                                                                        padding:
                                                                            '5px',
                                                                    }}>
                                                                    <strong>
                                                                        {
                                                                            logs?.notes
                                                                        }
                                                                    </strong>{' '}
                                                                    <br />
                                                                    {
                                                                        logs?.address
                                                                    }
                                                                    {logs.lat && logs.lon && (
                                                                      <div style={{fontSize:'70%'}}>({logs.lat.toFixed(5)},{logs.lon.toFixed(5)})</div>
                                                                    )}
                                                                </td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td
                                                                    style={{
                                                                        color: 'red',
                                                                        padding:
                                                                            '5px',
                                                                    }}>
                                                                    {moment(
                                                                        logs?.created_at ||
                                                                            moment(),
                                                                    ).format(
                                                                        'YYYY-DD-MM hh:mm A',
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        color: 'red',
                                                                        padding:
                                                                            '5px',
                                                                    }}>
                                                                    {
                                                                        logs?.notes
                                                                    }
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    )}
                                    {orders?.cancellation_reason !== null && (
                                        <div style={{margin: '20px'}}>
                                            <Divider style={{color:"#e21313"}}
                                                type="horizontal"
                                                orientation="center">
                                               Order cancellation reason
                                            </Divider>
                                            <table
                                                className="border"
                                                border={1}>
                                                <tr>
                                                    <th>{orders?.cancellation_reason	}</th>
                                                </tr>

                                            </table>
                                        </div>
                                    )}
                                    {/* <Divider type="horizontal" orientation="center">
                                    Order Log(s)
                                </Divider>
                                <div className="container">
                                    <ul className="responsive-table">
                                        <li className="table-header">

                                            <div className="col col-3">
                                                Notes
                                            </div>
                                            <div className="col col-3">
                                                Type
                                            </div>
                                            <div className="col col-4">
                                                Created On
                                            </div>
                                        </li>
                                        {orderLogs?.map(logs => (
                                            <li
                                                key={logs?.id}
                                                className="table-row">

                                                <div
                                                    className="col col-3"
                                                    data-label="Amount">
                                                    {logs?.notes}
                                                </div>
                                                <div className="col col-3">

                                                       {
                                            logs.type === "info" ? (
                                                <Tag color="green">Info</Tag>
                                            ) : (
                                                <Tag color="red">Error</Tag>
                                            )
                                        }


                                            </div>
                                                <div
                                                    className="col col-4"
                                                    data-label="Payment Status">
                                                    {moment(
                                                        logs?.created_at ||
                                                        moment(),
                                                    ).format(
                                                        'YYYY-DD-MM hh:mm A',
                                                    )}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                                </List>
                            </Col>
                        </div>

                        <div className="order">
                            {JSON.stringify(homeServices) !== '{}' && (
                                <Col sm={4} md={6} xl={10}>
                                    <List
                                        size="small"
                                        bordered
                                        style={{
                                            background: 'rgba(0,0,0,0.03)',
                                            marginBottom: 20,
                                        }}
                                        header={
                                            <Title level={5}>
                                                Home Services
                                            </Title>
                                        }>
                                        {Object.entries(homeServices).map(
                                            item => {
                                                return (
                                                    <Item
                                                        title={`${item[0]}`}
                                                        body={`${item[1]}`}
                                                    />
                                                )
                                            },
                                        )}
                                    </List>
                                </Col>
                            )}
                            <Col sm={4} md={6} xl={10}>
                                <List size="small">
                                    {orders.order_info
                                        ?.multiple_item_remove && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.multiple_item_remove ===
                                                true
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            title={'Multiple Item Remove'}
                                        />
                                    )}
                                    {orders.order_info?.payingby_job && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.payingby_job === true
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            title={'Paying by Job'}
                                        />
                                    )}
                                    {orders.order_info?.payingby_hour && (
                                        <Item
                                            body={orders.order_info?.noof_hour}
                                            title={'No of Hours'}
                                        />
                                    )}
                                    {orders.order_info?.sizeof_yard &&
                                        orders.order_info?.sizeof_yard !=
                                            'Other' && (
                                            <Item
                                                body={
                                                    orders.order_info
                                                        ?.sizeof_yard
                                                }
                                                title={'Size of Yard'}
                                            />
                                        )}
                                    {orders.order_info?.sizeof_yard_other && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.sizeof_yard_other
                                            }
                                            title={'Size of Yard'}
                                        />
                                    )}
                                    {orders.order_info?.lengthof_grass && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.lengthof_grass
                                            }
                                            title={'Length Of Grass'}
                                        />
                                    )}
                                    {orders.order_info?.removal_type && (
                                        <Item
                                            body={
                                                orders.order_info?.removal_type
                                            }
                                            title={'Removal Type'}
                                        />
                                    )}
                                    {orders.order_info?.include_edging !==
                                        null && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.include_edging == true
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            title={'Include Edging'}
                                        />
                                    )}
                                    {orders.order_info?.colorof_mulch &&
                                        orders.order_info?.colorof_mulch !==
                                            'Other' && (
                                            <Item
                                                body={
                                                    orders.order_info
                                                        ?.colorof_mulch
                                                }
                                                title={'Color of Mulch'}
                                            />
                                        )}
                                    {orders.order_info?.colorof_mulch_other && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.colorof_mulch_other
                                            }
                                            title={'Color of Mulch'}
                                        />
                                    )}

                                    {orders.order_info?.size_coverage && (
                                        <Item
                                            body={
                                                orders.order_info?.size_coverage
                                            }
                                            title={'Size Coverage'}
                                        />
                                    )}
                                    {orders.order_info?.width != '0' && (
                                        <Item
                                            body={orders.order_info?.width}
                                            title={'Width'}
                                        />
                                    )}

                                    {orders.order_info?.length != '0' && (
                                        <Item
                                            body={orders.order_info?.length}
                                            title={'Length'}
                                        />
                                    )}
                                    {orders.order_info?.square_feet != '0' && (
                                        <Item
                                            body={
                                                orders.order_info?.square_feet
                                            }
                                            title={'Square Feet'}
                                        />
                                    )}

                                    {+orders.order_info?.noof_bag > 0 && (
                                        <Item
                                            body={orders.order_info?.noof_bag}
                                            title={'No Of Bags'}
                                        />
                                    )}

                                    {+orders.order_info?.bushe_count > 0 && (
                                        <Item
                                            body={
                                                orders.order_info?.bushe_count
                                            }
                                            title={'Bushes'}
                                        />
                                    )}
                                    {+orders.order_info?.hedge_count > 0 && (
                                        <Item
                                            body={
                                                orders.order_info?.hedge_count
                                            }
                                            title={'Hedges'}
                                        />
                                    )}
                                    {+orders.order_info?.large_tree_count >
                                        0 && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.large_tree_count
                                            }
                                            title={'Large Trees'}
                                        />
                                    )}
                                    {+orders.order_info?.small_tree_count >
                                        0 && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.small_tree_count
                                            }
                                            title={'Small Trees'}
                                        />
                                    )}
                                    {+orders.order_info?.plant_count > 0 && (
                                        <Item
                                            body={
                                                orders.order_info?.plant_count
                                            }
                                            title={'Plants'}
                                        />
                                    )}
                                    {+orders.order_info?.noof_rider > 0 && (
                                        <Item
                                            body={orders.order_info?.noof_rider}
                                            title={'No of Rider'}
                                        />
                                    )}
                                    {orders.order_info?.delivery_needed !==
                                        null && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.delivery_needed === true
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            title={'Delivery Needed'}
                                        />
                                    )}
                                    {orders.order_info?.howto_dispose && (
                                        <Item
                                            body={
                                                orders.order_info?.howto_dispose
                                            }
                                            title={'How to Dispose'}
                                        />
                                    )}
                                    {orders.order_info?.need_own_equipment !==
                                        null && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.need_own_equipment ===
                                                true
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                            title={'Need Own Equipment'}
                                        />
                                    )}
                                    {orders.order_info?.add_other_first_val && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.add_other_first_count
                                            }
                                            title={
                                                orders.order_info
                                                    ?.add_other_first_val
                                            }
                                        />
                                    )}
                                    {orders.order_info
                                        ?.add_other_second_val && (
                                        <Item
                                            body={
                                                orders.order_info
                                                    ?.add_other_second_count
                                            }
                                            title={
                                                orders.order_info
                                                    ?.add_other_second_val
                                            }
                                        />
                                    )}
                                </List>
                            </Col>
                        </div>
                    </div>
                    <Divider type="horizontal" orientation="center">
                        Total Order Charges
                    </Divider>
                    <div className="order__total">
                        <div className="total__charge">
                            <h2>Cost of Goods</h2>
                            <h2>${costOfGoods}</h2>
                        </div>
                        <div className="total__charge">
                            <h2>Gopher Fee</h2>
                            <h2>+ ${gopherFee}</h2>
                        </div>
                        <div className="total__charge">
                            <h2>Offer</h2>
                            <h2>+ ${OrderOffer}</h2>
                        </div>

                        <div className="total__charge">
                            <h2>Age Restricted fee </h2>
                            <h2>+ ${age_restricted_fee}</h2>
                        </div>

                        <div className="total__charge">
                            <h2>Trust Shield Discount</h2>
                            <h2>- ${age_restricted_discount}</h2>
                        </div>
                        <div className="total__charge">
                            <h2>Instant Transfer Fee (8%)</h2>
                            <h2>+ ${parseFloat(instantResult).toFixed(2)}</h2>
                        </div>
                        <div className="total__charge">
                            <h2>Discount</h2>
                            <h2>- ${Discount}</h2>
                        </div>

                        <div className="total__chargeResult">
                            <h2> Total Order Charges</h2>
                            <h2> ${parseFloat(finalResult).toFixed(2)}</h2>
                        </div>
                    </div>

                    {images?.length !== 0 && (
                        <>
                            <Divider type="horizontal" orientation="center">
                                Order Attachment(s)
                            </Divider>

                            <div className="order__attach">
                                {images?.map((image, index) => (
                                    <Image key={index} src={image} />
                                ))}
                            </div>
                        </>
                    )}

                    {inApp?.length !== 0 && (
                        <>
                            <Divider type="horizontal" orientation="center">
                                In-App Messages
                            </Divider>

                            <div className="message__section">
                                {inApp?.map((inapp, index) => (
                                    <>
                                        <Button
                                            key={inapp.id}
                                            onClick={() => chat(index)}
                                            type="primary"
                                            style={{
                                                margin: '10px',
                                            }}>
                                            {inapp.gopher_name}
                                        </Button>
                                    </>
                                ))}
                            </div>
                        </>
                    )}

                    <Modal
                        title="In-App messages"
                        visible={isOpen}
                        onOk={closeModal}
                        onCancel={closeModal}>
                        <div className="modal" style={{padding: '5px'}}>
                            {/* <div className="popup">
                                        <div className="counter">
                                            <h1 style={{fontSize:'12px',marginLeft: '18px'}}>Requestor</h1>
                                            <h1 style={{fontSize:'12px',marginRight: '35px'}}>Gopher</h1>
                                        </div>

                                        <div className="chat__body">
                                            {messagess?.map(message => (
                                                <p
                                                    key={message?.id}
                                                    className={`chat__message ${message?.gopher &&
                                                        'chat__reciever'
                                                        }`}>
                                                    <span className="chat__name">
                                                        <h3 style={{ fontWeight: 'bold'}} > <Link to={`/user/${message.sender_id}`}> {message?.sent_from}</Link></h3>
                                                    </span>
                                                    {message?.content} <br />
                                                    <span className="chat__timestamp">
                                                        {moment(
                                                            message?.created_at,
                                                        ).format(
                                                            'YYYY-DD-MM (hh:mm A)',
                                                        )}
                                                    </span>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flag__new">
                           <div className="flag__message">
                               <h1 style={{fontSize:'12px',marginLeft:'-76px'}}>Flag(s)</h1>

                           </div>
                           {flag?.map(flag => (
                               <div key={flag?.id} className="flag__desc" style={{marginLeft:'137px'}}>
                                   <h1>{flag?.flag_by}</h1>
                                   <p>{flag?.description}</p>
                               </div>
                           ))}
                       </div> */}
                            <Row>
                                <Col md={16}>
                                    <div
                                        className="popup"
                                        style={{width: '350px'}}>
                                        <div
                                            className="counter"
                                            style={{width: '350px'}}>
                                            <h1
                                                style={{
                                                    fontSize: '12px',
                                                    marginLeft: '18px',
                                                }}>
                                                Requestor
                                            </h1>
                                            <h1
                                                style={{
                                                    fontSize: '12px',
                                                    marginRight: '35px',
                                                }}>
                                                Gopher
                                            </h1>
                                        </div>

                                        <div className="chat__body">
                                            {messagess?.map(message => (
                                                <p
                                                    key={message?.id}
                                                    className={`chat__message ${
                                                        message?.gopher &&
                                                        'chat__reciever'
                                                    }`}>
                                                    <span className="chat__name">
                                                        <h3
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                            }}>
                                                            {' '}
                                                            <Link
                                                                to={`/user/${message.sender_id}`}>
                                                                {' '}
                                                                {
                                                                    message?.sent_from
                                                                }
                                                            </Link>
                                                        </h3>
                                                    </span>

                                                    {/* {message?.content } */}
                                                    {isImage(
                                                        message?.content,
                                                    ) ? (
                                                        // <Image
                                                        //     width={30}
                                                        //     src={
                                                        //         // message?.content
                                                        //         'https://gopherror.s3.amazonaws.com/uploads/attachment/file/322/attachement_1.jpg'
                                                        //     }
                                                        //     alt="pic"
                                                        // />
                                                        <ImageViewer>
                                                            <img
                                                                className="w-10"
                                                                src={
                                                                    message?.content
                                                                    // 'https://gopherror.s3.amazonaws.com/uploads/attachment/file/322/attachement_1.jpg'
                                                                }
                                                                alt="pic"
                                                            />
                                                        </ImageViewer>
                                                    ) : (
                                                        <p className="text-[13px]">
                                                            {message?.content}
                                                        </p>
                                                    )}
                                                    {/* <br /> */}
                                                    <span className="chat__timestamp">
                                                        {moment(
                                                            message?.created_at,
                                                        ).format(
                                                            'YYYY-DD-MM (hh:mm A)',
                                                        )}
                                                    </span>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className="flag__new">
                                        <div
                                            className="flag__message"
                                            style={{
                                                width: '159px',
                                                marginLeft: '10px',
                                            }}>
                                            <h1
                                                style={{
                                                    fontSize: '12px',
                                                    marginLeft: '-12px',
                                                }}>
                                                Flag(s)
                                            </h1>
                                        </div>
                                        {flag?.map(flag => (
                                            <div
                                                key={flag?.id}
                                                className="flag__desc"
                                                style={{marginLeft: '16px'}}>
                                                <h1>{flag?.flag_by}</h1>
                                                <p>{flag?.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal>

                    {reports?.length !== 0 && (
                        <>
                            <div className="applied__div">
                                <Divider type="horizontal" orientation="center">
                                    Order Flag(s)
                                </Divider>
                                <div className="container">
                                    <ul className="responsive-table">
                                        <li className="table-header">
                                            <div className="col col-2">
                                                Username
                                            </div>
                                            <div className="col col-3">
                                                Description
                                            </div>
                                            <div className="col col-4">
                                                Reported On
                                            </div>
                                        </li>
                                        {reports?.map(report => (
                                            <li
                                                key={report?.id}
                                                className="table-row">
                                                <div
                                                    className="col col-2"
                                                    data-label="Customer Name">
                                                    {report?.username}
                                                </div>
                                                <div
                                                    className="col col-3"
                                                    data-label="Amount">
                                                    {report?.description}
                                                </div>
                                                <div
                                                    className="col col-4"
                                                    data-label="Payment Status">
                                                    {moment(
                                                        report?.created_at ||
                                                            moment(),
                                                    ).format(
                                                        'YYYY-DD-MM hh:mm A',
                                                    )}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}

                    {offers?.length !== 0 && (
                        <>
                            <div className="applied__div">
                                <Divider type="horizontal" orientation="center">
                                    Gopher(s) Applied
                                </Divider>
                                <div className="container">
                                    <ul className="responsive-table">
                                        <li className="table-header">
                                            <div className="col col-2">
                                                Gopher's Name
                                            </div>
                                            <div className="col col-3">
                                                Applied On
                                            </div>
                                            <div className="col col-4">
                                                Status
                                            </div>
                                        </li>
                                        {offers?.map(offer => (
                                            <li
                                                key={offer?.id}
                                                className="table-row">
                                                <div
                                                    className="col col-2"
                                                    data-label="Customer Name">
                                                    {/* {offer?.gopher} */}
                                                    {
                                                        <Link
                                                            to={`/user/${offer.user_id}`}>
                                                            {offer?.gopher}
                                                        </Link>
                                                    }
                                                </div>
                                                <div
                                                    className="col col-3"
                                                    data-label="Amount">
                                                    {moment(
                                                        offer?.createdon ||
                                                            moment(),
                                                    ).format(
                                                        'YYYY-DD-MM (hh:mm A)',
                                                    )}
                                                </div>
                                                <div
                                                    className="col col-4"
                                                    data-label="Payment Status">
                                                    <button
                                                        className={
                                                            offer?.status
                                                        }>
                                                        {offer?.status}
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}

                    {orderCogs?.length !== 0 && (
                        <>
                            <div className="applied__div">
                                <Divider type="horizontal" orientation="center">
                                    Orders Cogs
                                </Divider>
                                <div className="container">
                                    <ul className="responsive-table">
                                        <li className="table-header">
                                            <div className="col col-6">Id</div>

                                            <div className="col col-6">
                                                Original Offer ($)
                                            </div>
                                            <div className="col col-6">
                                                Original Cost ($)
                                            </div>
                                            <div className="col col-6">
                                                New Offer ($)
                                            </div>
                                            <div className="col col-6">
                                                New Cost ($)
                                            </div>
                                            <div className="col col-6">
                                                Comments
                                            </div>
                                            <div className="col col-6">
                                                Active
                                            </div>

                                            <div className="col col-6">
                                                Created On
                                            </div>
                                        </li>
                                        {orderCogs?.map(cogs => (
                                            <li
                                                key={cogs?.id}
                                                className="table-row">
                                                <div className="col col-6">
                                                    {cogs?.order_id || 'N/A'}
                                                </div>
                                                <div className="col col-6">
                                                    {cogs?.original_offer /
                                                        100 || 'N/A'}
                                                </div>
                                                <div className="col col-6">
                                                    {cogs?.original_cost /
                                                        100 || 'N/A'}
                                                </div>
                                                <div className="col col-6">
                                                    {cogs?.new_offer / 100 ||
                                                        'N/A'}
                                                </div>
                                                <div className="col col-6">
                                                    {cogs?.new_cost / 100 ||
                                                        'N/A'}
                                                </div>
                                                <div className="col col-6">
                                                    {cogs?.comments || 'N/A'}
                                                </div>

                                                <div className="col col-6">
                                                    {/* <button
                                                        className={`cogs-${cogs?.active}`}>
                                                        {cogs?.active === true && "Accepted"}
                                                        {cogs?.active === false && "Rejected"}
                                                        {cogs?.active === null && "Pending"}
                                                    </button> */}
                                                    <button
                                                        className={`cogs-${cogs?.active}`}>
                                                        {cogs?.active ===
                                                            true &&
                                                            cogs?.viewed ===
                                                                true &&
                                                            'Accepted'}
                                                        {cogs?.active ===
                                                            false &&
                                                            cogs?.viewed ===
                                                                true &&
                                                            'Declined'}
                                                        {cogs?.active ===
                                                            null && 'Pending'}
                                                    </button>
                                                </div>

                                                <div className="col col-6">
                                                    {moment(
                                                        cogs?.created_at ||
                                                            moment(),
                                                    ).format(
                                                        'YYYY-DD-MM (hh:mm A)',
                                                    ) || 'N/A'}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}

                    {counterOffers?.length !== 0 && (
                        <>
                            <div className="applied__div">
                                <Divider type="horizontal" orientation="center">
                                    Counter Offers(s)
                                </Divider>
                                <div className="container">
                                    <ul className="responsive-table">
                                        <li className="table-header">
                                            <div className="col col-2">ID</div>
                                            <div className="col col-2">
                                                Gopher ($)
                                            </div>
                                            <div className="col col-3">
                                                Offer ($)
                                            </div>
                                            <div className="col col-3">
                                                Counter Offer
                                            </div>
                                            <div className="col col-5">
                                                Cost of goods ($)
                                            </div>
                                            <div className="col col-5">
                                                COG Counter ($)
                                            </div>
                                            <div className="col col-5">
                                                Created On
                                            </div>
                                            <div className="col col-5">
                                                Status
                                            </div>
                                            <div className="col col-5">
                                                Comment
                                            </div>
                                        </li>
                                        {counterOffers?.map(offer => (
                                            <li
                                                key={offer?.id}
                                                className="table-row">
                                                <Link
                                                    to={`/counter-offer/${offer?.id}`}
                                                    className="col col-2">
                                                    {offer?.id}
                                                </Link>

                                                <div className="col col-2">
                                                    {/* {offer?.gopher} */}
                                                    {offer.id ? (
                                                        <Link
                                                            to={`/user/${offer.user_id}`}>
                                                            {offer?.gopher}
                                                        </Link>
                                                    ) : (
                                                        <Tag></Tag>
                                                    )}
                                                </div>
                                                <div className="col col-3">
                                                    $
                                                    {parseFloat(
                                                        offer?.order_offer /
                                                            100,
                                                    ).toFixed(2)}
                                                </div>
                                                <div className="col col-3">
                                                    $
                                                    {parseFloat(
                                                        offer?.offer / 100,
                                                    ).toFixed(2)}
                                                </div>
                                                <div className="col col-5">
                                                    $
                                                    {parseFloat(
                                                        offer?.order_cog / 100,
                                                    ).toFixed(2)}
                                                </div>
                                                <div className="col col-5">
                                                    $
                                                    {parseFloat(
                                                        offer?.cost_of_goods /
                                                            100,
                                                    ).toFixed(2)}
                                                </div>
                                                <div className="col col-5">
                                                    {moment(
                                                        offer?.created_at ||
                                                            moment(),
                                                    ).format(
                                                        'YYYY-DD-MM (hh:mm A)',
                                                    )}
                                                </div>
                                                <div className="col col-5">
                                                    <button
                                                        className={
                                                            offer?.status
                                                        }>
                                                        {offer?.status}
                                                    </button>
                                                </div>
                                                <div className="col col-5">
                                                    {offer?.comment}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {bids?.length !== 0 && (
                        <>
                            <div className="applied__div">
                                <Divider type="horizontal" orientation="center">
                                    Gopher Bid(s)
                                </Divider>
                                <div className="container">
                                    <ul className="responsive-table">
                                        <li className="table-header">
                                            <div className="col col-2">ID</div>
                                            <div className="col col-2">
                                                Gopher
                                            </div>
                                            <div className="col col-3">
                                                Offer ($)
                                            </div>
                                            <div className="col col-5">
                                                Cost of goods ($)
                                            </div>
                                            <div className="col col-5">
                                                Created On
                                            </div>
                                            <div className="col col-5">
                                                Status
                                            </div>
                                            <div className="col col-5">
                                                Comment
                                            </div>
                                        </li>
                                        {bids?.map(bids => (
                                            <li className="table-row">
                                                <div className="col col-2">
                                                    {/* {bids?.id}/ */}
                                                    <Link
                                                        to={`/bids/${bids.id}`}>
                                                        {bids.id}
                                                    </Link>
                                                </div>
                                                {/* 1600
                                                }
                                                */}
                                                <div className="col col-3">
                                                    {/* {bids?.gopher} */}

                                                    {bids.id ? (
                                                        <Link
                                                            to={`/user/${bids.user_id}`}>
                                                            {bids?.gopher}
                                                        </Link>
                                                    ) : (
                                                        <Tag></Tag>
                                                    )}
                                                </div>
                                                <div className="col col-5">
                                                    $
                                                    {parseFloat(
                                                        bids?.offer / 100,
                                                    ).toFixed(2)}
                                                </div>
                                                <div className="col col-5">
                                                    $
                                                    {parseFloat(
                                                        bids?.cost_of_goods /
                                                            100,
                                                    ).toFixed(2)}
                                                </div>
                                                <div className="col col-5">
                                                    {moment(
                                                        bids?.createdon ||
                                                            moment(),
                                                    ).format(
                                                        'YYYY-DD-MM (hh:mm A)',
                                                    )}
                                                </div>
                                                <div className="col col-5">
                                                    <button
                                                        className={
                                                            bids?.status
                                                        }>
                                                        {bids?.status}
                                                    </button>
                                                </div>
                                                <div className="col col-5">
                                                    {bids?.comment || 'N/A'}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                     {age_restricted_attachment_id && (
                        <>
                            <Divider type="horizontal" orientation="center">
                                Age Restricted Id(s)
                            </Divider>

                            <div className="order__attach">
                                    <Image height={200} src={age_restricted_attachment_id} />
                            </div>
                        </>
                    )}
                    {ratings?.length !== 0 && (
                        <>
                            <Divider type="horizontal" orientation="center">
                                Rating(s)
                            </Divider>
                            <div className="rating">
                                {ratings?.map(ratings => (
                                    <div
                                        key={ratings?.id}
                                        className="rating__gopher">
                                        <h2>{ratings?.rater}</h2>
                                        <h2>
                                            {Array(ratings?.score)
                                                .fill()
                                                .map(_ => '⭐')}
                                            <span>({ratings?.score})</span>
                                        </h2>
                                        <h4>{ratings?.review}</h4>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <OrderJsonModal
                        viewOrderJson={viewOrderJson}
                        setViewOrderJson={setViewOrderJson}
                        orderJson={orderJson}
                    />
                    <Modal
                        title="Why is the Order being Cancelled?"
                        visible={OrderCancelModal}
                        onOk={ConfirmOrderCancel}
                        onCancel={openCloseOrderCancelModal}
                        onTitleClick={openCloseOrderCancelModal}>
                        <div>
                            <label>Reason :</label>
                            <TextArea
                                rows={4}
                                value={OrderCancelReason}
                                onChange={handleOrderCancelReason}
                            />
                        </div>
                    </Modal>
                </>
            )}
        </>
    )
}

const OrderJsonModal = ({viewOrderJson, setViewOrderJson, orderJson}) => {
    const [visibleJsonIndex,setVisibleIndex] = useState(null)
    const [selectionArr,setSelectionArr]=useState([])
    const [diffModal,setDiffModal] = useState(false)
    const [loading,setLoading] = useState(false)
    const indexArr = []
    function closeJsonModal() {
        setViewOrderJson(false)
    }
    function handleShowButton(index){
      setVisibleIndex(prevState=>prevState!=index?index:null)
    }

    function jsonComparehandler(index){
       if(selectionArr.includes(index)){
            const arr = selectionArr.filter((item)=>item!=index)
            setSelectionArr([...arr])
       }
       else if(selectionArr.length<2){
        setSelectionArr([...selectionArr,index])
       }
    }
    function handalJsonDiffModal(){
        setDiffModal(!diffModal)
    }

    function loadingViewJsonDiffModal(){
        selectionArr.map((index)=>{
            if(orderJson[selectionArr[0]].id>orderJson[selectionArr[1]].id){
                let new_arr = [...selectionArr]
                let pop_value = new_arr.pop()
                new_arr.unshift(pop_value)
                setSelectionArr([...new_arr])
            }
        })
        setLoading(true)
        setTimeout(() => {
            setDiffModal(!diffModal)
            setLoading(false)
        }, 3000);
    }
    return (
        <>
            <Modal
                width={700}
                title="Order JSON"
                visible={viewOrderJson}
                onOk={closeJsonModal}
                onCancel={closeJsonModal}
                onTitleClick={closeJsonModal}
                footer={[
                    <Button style={{float:'left'}} key="submit" type="primary" loading={loading} onClick={loadingViewJsonDiffModal} disabled={selectionArr.length<2}>
                      Compare
                    </Button>,
                    <Button key='submit' onClick={closeJsonModal}>Close</Button>
                  ]}
                >
              {orderJson.map((order,index)=>{
                indexArr.push(false)
                return <>
                <OrderJsonListComponent
                selectionArr={selectionArr}
                orderJson={orderJson}
                visibleJsonIndex={visibleJsonIndex}
                index={index}
                order={order}
                handleShowButton={handleShowButton}
                jsonComparehandler={jsonComparehandler}
                diffModal={diffModal}
                handalJsonDiffModal={handalJsonDiffModal}
                ></OrderJsonListComponent>
                </>
              })}
            </Modal>
        </>
    )
}

const OrderJsonListComponent = ({
    selectionArr,
    orderJson,
    visibleJsonIndex,
    index,
    order,
    handleShowButton,
    jsonComparehandler,
    diffModal,
    handalJsonDiffModal
})=>{
return <>
                {diffModal && <JsonDiffViewer handalJsonDiffModal={handalJsonDiffModal} firstjson={selectionArr.length?orderJson[selectionArr[0]]:null} secondjson={selectionArr.length?orderJson[selectionArr[1]]:null}></JsonDiffViewer>}
                <div style={{display:'flex'}}>
                <Checkbox style={{marginRight:'10px',fontSize:'16px'}} checked={selectionArr.includes(index)} disabled={selectionArr.length>2} onChange={()=>jsonComparehandler(index)}></Checkbox>
                <Button style={{marginBottom:'5px'}} key={index} type="primary" block onClick={()=>handleShowButton(index)}>
                {order.title}  {moment(order.created_at).format('YYYY-DD-MM (hh:mm A)')}
                   </Button>
                </div>
                <JsonViewComponent order={order} visible={visibleJsonIndex==index?visibleJsonIndex:null}/>
</>
}

const JsonViewComponent = ({order,visible})=>{
    return <> {visible!=null && (<ReactJson
        theme={'isotope'}
        displayDataTypes={false}
        enableClipboard={false}
        collapsed={1}
        src={order.metadata}
    />)}
    </>

}

const JsonDiffViewer = ({firstjson,secondjson,handalJsonDiffModal}) => {
    function diffmodalhandler(){
        handalJsonDiffModal(false)
    }
    return <>
    <Modal
    width={800}
    title="Order JSON Diffrence"
    visible={firstjson}
    onOk={diffmodalhandler}
    onCancel={diffmodalhandler}
    onTitleClick={diffmodalhandler}>
     <ReactJsonViewCompare oldData={firstjson.metadata} newData={secondjson.metadata} />;
    </Modal>
    </>
}

export default BookingView
